import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Table, Input, Select, Button, message, Modal, Tag } from 'antd';  // Added Tag component from Ant Design
import { MdCloudUpload } from "react-icons/md";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'; // Ant Design icons
import { FcOk } from "react-icons/fc"; // OK icon for active
import { RxCross2 } from "react-icons/rx"; // Cross icon for disabled

const { Option } = Select;
const { confirm } = Modal;

const Variant = () => {
  const [variants, setVariants] = useState([]);
  const [brands, setBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);  // State for file upload
  const [showSendButton, setShowSendButton] = useState(false);  // State for showing the save button

  // Fetch brands when component mounts
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API}/api/brands`;
        const res = await fetch(apiUrl);
        if (!res.ok) throw new Error(`Failed to fetch brands: ${res.status} ${res.statusText}`);
        const data = await res.json();
        setBrands(data.data || data);
      } catch (err) {
        message.error('Failed to fetch brands');
      }
    };
    fetchBrands();
  }, []);

  // Fetch variants based on searchTerm and selectedBrand
  useEffect(() => {
    const fetchVariants = async () => {
      setLoading(true);
      try {
        let url = '';
        let params = [];

        if (searchTerm) {
          url = `${process.env.REACT_APP_API}/api/search`;
          params.push(`searchTerm=${encodeURIComponent(searchTerm)}`);
        } else if (selectedBrand) {
          url = `${process.env.REACT_APP_API}/api/search-variants`;
          params.push(`brand_id=${selectedBrand}`);
        } else {
          url = `${process.env.REACT_APP_API}/api/get-all-varient`;
        }

        if (params.length > 0) {
          url += '?' + params.join('&');
        }

        const res = await fetch(url);
        if (!res.ok) throw new Error(`Failed to fetch variants: ${res.status} ${res.statusText}`);
        const data = await res.json();
        setVariants(data.data || data);
      } catch (err) {
        message.error('Failed to fetch variants');
      } finally {
        setLoading(false);
      }
    };

    fetchVariants();
  }, [searchTerm, selectedBrand]);

  const deleteVariant = async (id) => {
    confirm({
      title: 'Do you want to delete this variant?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        try {
          const result = await fetch(`${process.env.REACT_APP_API}/api/delete/${id}`, { method: 'DELETE' });
          if (result.ok) {
            message.success('Variant has been deleted');
            setVariants((prevVariants) => prevVariants.filter((variant) => variant._id !== id));
          } else {
            throw new Error('Failed to delete variant');
          }
        } catch (error) {
          message.error('Error deleting variant');
        }
      },
      onCancel() {
        message.info('Delete action cancelled');
      },
    });
  };

  const columns = [
    {
      title: 'Sr No',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Brand',
      dataIndex: ['brand_id', 'name'],
      key: 'brand',
      sorter: (a, b) => {
        const brandA = (a.brand_id?.name || '').toLowerCase();
        const brandB = (b.brand_id?.name || '').toLowerCase();
        return brandA.localeCompare(brandB);
      },
      render: (text) => text || 'N/A',
    },
    {
      title: 'Car',
      dataIndex: ['product_id', 'carname'],
      key: 'car',
      render: (text) => text || 'N/A',
    },
    {
      title: 'Variant',
      dataIndex: 'varientName',
      key: 'variant',
    },
    {
      title: 'Fuel',
      dataIndex: 'fuel',
      key: 'fuel',
    },
    {
      title: 'Price',
      dataIndex: 'exShowroomPrice',
      key: 'price',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Variant Id',
      dataIndex: '_id',
      key: '_id',
      render: (text) => (
        <span
          onClick={() => navigator.clipboard.writeText(text).then(() => message.success(`Copied Variant Id: ${text}`))}
          style={{ cursor: 'pointer' }}
          title="Click to copy"
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (
        active === 'true' 
          ? <Tag color="green">Active <FcOk /></Tag>
          : <Tag color="red">Disabled <RxCross2 /></Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className="d-flex gap-2 align-items-center justify-content-center">
          <Link to={`/editvariant/${record._id}`} style={{ textDecoration: 'none' }}>
            <Button type="primary" shape="circle" icon={<EditOutlined />} />
          </Link>
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => deleteVariant(record._id)}
          />
        </div>
      ),
    },
  ];

  const handleFileChange = event => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();
      if (fileType === 'csv') {
        setFile(uploadedFile);
        setShowSendButton(true);
      } else {
        message.error("Please upload a .csv file.");
        event.target.value = null;
        setFile(null);
        setShowSendButton(false);
      }
    }
  };

  const addCsv = async () => {
    let formData = new FormData();
    formData.append("file", file);
    try {
      let result = await fetch(`${process.env.REACT_APP_API}/api/v1/import-varient-csv`, {
        method: "POST",
        body: formData,
      });
      if (result.ok) {
        message.success("File uploaded successfully!", 4, () => {
          window.location.reload();
        });
      } else {
        message.error("Failed to upload file. Please try again.");
      }
    } catch (error) {
      message.error("An error occurred. Please try again later.");
    }
  };

  return (
    <section className='main-container-full'>
      <div className='d-flex justify-content-between mb-3 p-3'>
        {/* Brand Dropdown */}
        <div className='d-flex align-items-center'>
          <Select
            value={selectedBrand}
            onChange={setSelectedBrand}
            style={{ width: 250 }}
            placeholder="Select Brand"
          >
            <Option value="">All Brands</Option>
            {brands.map((brand) => (
              <Option key={brand._id} value={brand._id}>
                {brand.name}
              </Option>
            ))}
          </Select>
        </div>

        {/* Search Bar */}
        <div>
          <Input
            placeholder="Search by variant name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Handle search term change
            style={{ width: 300 }}
          />
        </div>

        {/* Add Variant Button */}
        <div>
          <Link to={"/addvariant"}>
            <Button type="primary">Add Variant</Button>
          </Link>
        </div>
      </div>

      {/* Variants Table */}
      <Table
        columns={columns}
        dataSource={variants}
        rowKey="_id"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />

      {/* CSV Upload */}
      <div className="App">
        <div className="upload-container">
          <MdCloudUpload className='MdCloudUpload' />
          <input className="input-hidden" type="file" onChange={handleFileChange} />
        </div>
        {showSendButton && (
          <div className='savebuton' style={{ right: file ? '60px' : '0' }} onClick={addCsv}>
            Save
          </div>
        )}
      </div>
    </section>
  );
};

export default Variant;

