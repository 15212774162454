import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, message, Pagination } from 'antd'; // Import Modal correctly
import { MdCloudUpload } from "react-icons/md";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BigImage from './Onclickimage';
import Popup from './Popup';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { confirm } = Modal; // Destructure Modal.confirm for confirmation dialogs

const Regtable = () => {
  const [brands, setBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [file, setFile] = useState(null);
  const [showSendButton, setShowSendButton] = useState(false);
  
  // State for controlling the popup visibility
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async () => {
    try {
      let result = await fetch(`${process.env.REACT_APP_API}/brands`);
      result = await result.json();
      setBrands(result.reverse());
    } catch (error) {
      message.error('Failed to fetch brands');
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteBrand = (id) => {
    confirm({
      title: 'Are you sure you want to delete this brand?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        try {
          let result = await fetch(`${process.env.REACT_APP_API}/brands/${id}`, { method: 'DELETE' });
          if (result.ok) {
            toast.success('Brand has been deleted');
            getBrands();
          } else {
            message.error('Failed to delete brand');
          }
        } catch (error) {
          message.error('An error occurred. Please try again later.');
        }
      },
    });
  };

  const editBrand = (id) => {
    confirm({
      title: 'Are you sure you want to edit this brand?',
      content: 'Confirm your changes before proceeding.',
      onOk: () => {
        // Navigate to edit page
        window.location.href = `/editbrand/${id}`;
      },
    });
  };

  const columns = [
    {
      title: 'Sr no',
      render: (text, record, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      title: 'Brand logo',
      dataIndex: 'image',
      key: 'image',
      render: (image, record) => (
        <BigImage
          src={`${process.env.REACT_APP_API}/brandImages/${image}`}
          alt={record.name}
        />
      ),
    },
    {
      title: 'Sales Ranking',
      dataIndex: 'salesRanking',
      key: 'salesRanking',
    },
    {
      title: 'Year',
      dataIndex: 'salesMonthAndYear',
      key: 'salesMonthAndYear',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Selling Since in India',
      dataIndex: 'selling',
      key: 'selling',
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Edited At',
      dataIndex: 'updatedAt',
      render: (updatedAt) => new Date(updatedAt).toLocaleString(),
    },
    {
      title: 'Brand Id',
      dataIndex: '_id',
      key: '_id',
      render: (text) => (
        <span
          onClick={() => navigator.clipboard.writeText(text).then(() => toast.success(`Copied Brand Id: ${text}`))}
          style={{ cursor: 'pointer' }}
          title="Click to copy"
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className="d-flex gap-2 align-items-center justify-content-center">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editBrand(record._id)}
          />
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => deleteBrand(record._id)}
          />
        </div>
      ),
    },
  ];

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.name.split('.').pop().toLowerCase() === 'csv') {
      setFile(uploadedFile);
      setShowSendButton(true);
    } else {
      message.error('Please upload a valid .csv file');
      setFile(null);
      setShowSendButton(false);
    }
  };

  const addCsv = async () => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const result = await fetch(`${process.env.REACT_APP_API}/api/v1/import-brand-csv`, {
        method: 'POST',
        body: formData,
      });
      if (result.ok) {
        toast.success('File uploaded successfully!');
        setFile(null);
        setShowSendButton(false);
        getBrands();
      } else {
        message.error('Failed to upload file');
      }
    } catch (error) {
      message.error('An error occurred. Please try again later.');
    }
  };

  return (
    <section className="main-container-full">
      <div className="d-flex justify-content-between mb-3 p-3">
        <div className="h2">Brands</div>
        <div className="d-flex justify-content-end" style={{ width: '100%' }}>
          <Button type="primary" onClick={() => setShowPopup(true)}>Add Brand</Button>
        </div>
      </div>

      <Popup showPopup={showPopup} togglePopup={() => setShowPopup(false)} />

      <Table
        columns={columns}
        dataSource={brands.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
        rowKey="_id"
        pagination={false}
      />

      <div className="d-flex justify-content-start mt-3">
        <Pagination
          current={currentPage}
          total={brands.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>

      <div className="App">
        <div className="upload-container">
          <MdCloudUpload className='MdCloudUpload' />
          <input className="input-hidden" type="file" onChange={handleFileChange} />
        </div>
        {showSendButton && (
          <div className='savebuton' style={{ right: file ? '60px' : '0' }} onClick={addCsv}>
            Save
          </div>
        )}
      </div>
      
      <ToastContainer />
    </section>
  );
};

export default Regtable;
