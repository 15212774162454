import React from 'react';



const Productpage = () => {
  return (
    <>

    </>
  )
}

export default Productpage;