import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Pagination, Select, Switch, Button, Upload, Modal, Tag } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdCloudUpload } from "react-icons/md";
import { FcOk } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;
const { confirm } = Modal;

const ProductTable = () => {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [showUpcoming, setShowUpcoming] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [showSendButton, setShowSendButton] = useState(false);
  const productsPerPage = 10;
  const totalPages = Math.ceil(products.length / productsPerPage);
  const navigate = useNavigate();

  const parseList = (htmlString) => {
    if (!htmlString) return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const items = doc.querySelectorAll('li');
    return Array.from(items).map(item => item.textContent).join(', ');
  };

  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * productsPerPage + index + 1;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let productsUrl = `${process.env.REACT_APP_API}/api/allproducts/mo-admin`;

      if (selectedBrand) {
        productsUrl = `${process.env.REACT_APP_API}/api/cars/brand/${selectedBrand}`;
      }

      const response = await fetch(productsUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      if (showUpcoming) {
        const upcomingCars = data.filter(car => car.isUpcoming === true);
        if (upcomingCars.length === 0) {
          showNoUpcomingProductsModal();
        } else {
          setProducts(upcomingCars);
        }
      } else {
        setProducts(data);
      }
    } catch (error) {
      toast.error('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const toggleProductStatus = (productId, isCurrentlyActive) => {
    confirm({
      title: `Are you sure you want to ${isCurrentlyActive ? 'disable' : 'enable'} the product and all of the connected brands?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        // Proceed with the toggle only if the user confirms
        updateProductStatus(productId, !isCurrentlyActive);
      },
    });
  };

  const updateProductStatus = async (productId, isActive) => {
    const formData = new FormData();
    formData.append('active', isActive ? "true" : "false");

    try {
      const result = await fetch(`${process.env.REACT_APP_API}/api/extra/cars-edit/${productId}`, {
        method: 'PUT',
        body: formData,
      });

      if (!result.ok) {
        throw new Error('Failed to update product status');
      }

      fetchData(); // Refresh product data
    } catch (error) {
      toast.error('Failed to update product status');
    }
  };

  const showNoUpcomingProductsModal = () => {
    Modal.info({
      title: 'No Upcoming Products',
      content: 'There are no upcoming products available at this moment. The toggle will be reset.',
      onOk() {
        setShowUpcoming(false);
      },
    });
  };

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/brands`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const brandsData = await response.json();
        setBrands(brandsData);
      } catch (error) {
        toast.error('Error fetching brands');
      }
    };

    fetchBrands();
    fetchData();
  }, [selectedBrand, showUpcoming]);

  const handleFileChange = event => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.name.split('.').pop().toLowerCase();
      if (fileType === 'csv') {
        setFile(uploadedFile);
        setShowSendButton(true);
      } else {
        event.target.value = null;
        setFile(null);
        setShowSendButton(false);
      }
    }
  };

  const addCsv = async () => {
    let formData = new FormData();
    formData.append("csvFile", file);
    try {
      let result = await fetch(`${process.env.REACT_APP_API}/api/v1/upload-product-csv`, {
        method: "POST",
        body: formData,
      });

      if (result.ok) {
        toast.success("File uploaded successfully!", {
          autoClose: 4000,
          onClose: () => {
            window.location.reload();
          }
        });
      } else {
        toast.error("Failed to upload file. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleBrandChange = (value) => {
    setSelectedBrand(value);
    setCurrentPage(1);
  };

  const handleUpcomingChange = () => {
    setShowUpcoming(!showUpcoming);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showEditConfirm = (productId) => {
    confirm({
      title: 'Are you sure you want to edit this product?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will be redirected to the edit page.',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        navigate(`/editproduct/${productId}`);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showDeleteConfirm = (productId) => {
    confirm({
      title: 'Are you sure you want to delete this product?',
      icon: <ExclamationCircleOutlined />,
      content: 'Once deleted, the product cannot be recovered.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteProduct(productId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const deleteProduct = async (productId) => {
    try {
      let result = await fetch(`${process.env.REACT_APP_API}/api/delete-product/${productId}`, {
        method: 'DELETE',
      });

      if (!result.ok) {
        throw new Error('Failed to delete product');
      }

      toast.success('Product has been deleted successfully!', { position: toast.POSITION.TOP_RIGHT });
      fetchData(); // Re-fetch data after deletion
    } catch (error) {
      toast.error('Failed to delete product');
    }
  };

  const columns = [
    { title: 'Sr no', dataIndex: 'serial', key: 'serial', render: (_, __, index) => calculateSerialNumber(index) },
    { title: 'Car Name', dataIndex: 'carname', key: 'carname' },
    { title: 'Brand', dataIndex: ['brand', 'name'], key: 'brand' },
    { title: 'Mo Rating', dataIndex: 'movrating', key: 'movrating' },
    { title: 'Seater Info', dataIndex: 'seater', key: 'seater', render: parseList },
    { title: 'Fuel Type', dataIndex: 'fueltype', key: 'fueltype', render: parseList },
    { title: 'Transmission Type', dataIndex: 'transmissiontype', key: 'transmissiontype', render: parseList },
    { title: 'NCAP', dataIndex: 'NCAP', key: 'NCAP' },
    {
      title: 'Product Id',
      dataIndex: '_id',
      key: '_id',
      render: (text) => <span onClick={() => navigator.clipboard.writeText(text)} style={{ cursor: 'pointer' }}>{text}</span>,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (active, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Switch
            checked={active === "true"}
            onChange={() => toggleProductStatus(record._id, active === "true")}
            style={{
              backgroundColor: active === "true" ? '#52c41a' : '#ff4d4f', // Green for active, red for inactive
              borderColor: active === "true" ? '#b7eb8f' : '#ffccc7',
            }}
          />
          <span style={{ color: active === "true" ? '#52c41a' : '#ff4d4f', fontSize: '12px' }}>
            {active === "true" ? 'Active' : 'Disable'}
          </span>
        </div>
      ),
    },
    
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className="d-flex gap-2 align-items-center justify-content-center">
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => showEditConfirm(record._id)}
          />
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className='main-container-full'>
      <div className='d-flex justify-content-between mb-3 p-3'>
        <Select
          value={selectedBrand}
          onChange={handleBrandChange}
          style={{ width: 250 }}
          placeholder="Select Brand"
        >
          <Option value="">All Brands</Option>
          {brands.map(brand => (
            <Option key={brand._id} value={brand._id}>{brand.name}</Option>
          ))}
        </Select>

        <div className='d-flex gap-3'>
          <Switch checked={showUpcoming} onChange={handleUpcomingChange} />
          <span>Show Upcoming Cars</span>
          <Link to={"/addproduct"}>
            <Button type="primary">Add Product</Button>
          </Link>
        </div>
      </div>

      <Table
        dataSource={products.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage)}
        columns={columns}
        rowKey="_id"
        pagination={false}
        loading={loading}
      />

      <div className='d-flex justify-content-between mt-3 p-3'>
        <Pagination
          current={currentPage}
          total={products.length}
          pageSize={productsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        />

<div className="App">
        <div className="upload-container">
          <MdCloudUpload className='MdCloudUpload' />
          <input className="input-hidden" type="file" onChange={handleFileChange} />
        </div>
        {showSendButton && (
          <div className='savebuton' style={{ right: file ? '60px' : '0' }} onClick={addCsv}>
            Save
          </div>
        )}
      </div>
      </div>

      <ToastContainer />
    </section>
  );
};

export default ProductTable;
