import React, { useState, useEffect } from 'react';
import { Table, Button, message, Select, Modal, Tag } from 'antd';
import { ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const OwnersReview = () => {
    const [review, setReview] = useState([]);
    const [filterRating, setFilterRating] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);

    const getReviews = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/tern/get-all-reviews`);
            if (res.data.success) {
                setReview(res.data.data);
            }
        } catch (e) {
            console.log(e);
            message.error("Failed to fetch reviews");
        }
    };

    useEffect(() => {
        getReviews();
    }, []);

    const handleStatus = async (record, status) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_API}/api/v1/tern/update-permission/${record._id}`, { permission: status });
            if (res.data.success) {
                message.success(res.data.message);
                getReviews();
            }
        } catch (e) {
            console.log(e);
            message.error("Something went wrong");
        }
    };

    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this review?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone and will permanently delete the review.',
            okText: 'Yes, Delete It',
            okType: 'danger',
            cancelText: 'No, Cancel',
            onOk() {
                return new Promise((resolve, reject) => {
                    axios.delete(`${process.env.REACT_APP_API}/api/v1/tern/delete-review/${id}`)
                        .then(res => {
                            if (res.data.success) {
                                message.success('Review deleted successfully');
                                getReviews();
                                resolve();
                            } else {
                                message.error('Failed to delete review');
                                reject();
                            }
                        })
                        .catch(e => {
                            console.error(e);
                            message.error('Error occurred while deleting review');
                            reject();
                        });
                });
            },
            onCancel() {
                console.log('Cancel delete');
            },
        });
    };

    const handleDownloadCsv = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/v1/tern/export-reviews`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'reviews.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the CSV file:', error);
            message.error('Failed to download CSV');
        }
    };

    const fetchReviewById = async (id) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/tern/get-review-by-id/${id}`);
            if (res.data.success) {
                return res.data.data;
            }
        } catch (error) {
            message.error('Failed to fetch review details');
        }
    };

    const handleViewDetails = async (id) => {
        try {
            const reviewData = await fetchReviewById(id);
            setSelectedReview(reviewData);
            setModalVisible(true);
        } catch (error) {
            console.error('Error fetching review details:', error);
        }
    };

    const columns = [
        {
            title: 'Brand',
            dataIndex: 'brand',
            render: brand => brand ? brand.name : 'No data'
        },
        {
            title: 'Car',
            dataIndex: 'product_id',
            render: product_id => product_id ? product_id.carname : 'No data'
        },
        {
            title: 'Name',
            dataIndex: 'customerName'
        },
        {
            title: 'Email',
            dataIndex: 'email_id'
        },
        {
            title: 'PIN Code',
            dataIndex: 'pinCode'
        },
        {
            title: 'Rating',
            dataIndex: 'ratingOfCar'
        },
        {
            title: 'Varient',
            dataIndex: 'varient'
        },
        {
            title: 'Car Number',
            dataIndex: 'carNumber'
        },
        {
            title: 'Status',
            dataIndex: 'permission',
            render: permission => {
                return permission === 'Approved' ? <Tag color="green">Approved</Tag> : <Tag color="red">Rejected</Tag>;
            }
        },
        {
            title: 'Time Stamp',
            dataIndex: 'createdAt',
            render: text => new Date(text).toLocaleString()
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 160,
            render: (text, record) => (
                <div className="d-flex gap-2 align-items-center justify-content-center" style={{ height: '100%', width: '144px' }}>
                    <CheckCircleOutlined className="text-custom-green" onClick={() => handleStatus(record, 'Approved')} />
                    <CloseCircleOutlined className="text-danger" onClick={() => handleStatus(record, 'Rejected')} />
                    <DeleteOutlined className="text-black" onClick={() => handleDelete(record._id)} />
                    <EyeOutlined className="text-black" onClick={() => handleViewDetails(record._id)} />
                </div>
            ),
        }
    ];

    const handleFilterRating = (value) => {
        setFilterRating(value ? parseInt(value, 10) : null);
    };

    const filteredReviews = filterRating != null
        ? review.filter(review => Math.floor(review.ratingOfCar) === filterRating)
        : review;

    return (
        <section className='main-container-full'>
            <div className="h2 mb-3">Owner's Review</div>
            <div className="d-flex justify-content-between align-items-baseline mb-3">
                <Select
                    allowClear
                    placeholder="Filter by Rating"
                    style={{ width: 200 }}
                    onChange={handleFilterRating}
                    value={filterRating}
                >
                    {[1, 2, 3, 4, 5].map(rating => (
                        <Option key={rating} value={rating}>
                            {rating}
                        </Option>
                    ))}
                </Select>
                <Button
                    type="danger"
                    icon={<DownloadOutlined />}
                    onClick={handleDownloadCsv}
                >
                    Download CSV
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={filteredReviews}
                rowKey="_id"
                pagination={{ pageSize: 50 }}
                scroll={{ x: '80vw' }}
                tableLayout="fixed"
            />

            <Modal
                title="Review Details"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                {selectedReview && (
                    <div className="review-details">
                        {selectedReview.imageOfCar ? (
                            <img src={`${process.env.REACT_APP_API}/ownersImages/${selectedReview.imageOfCar}`} alt="Car" />
                        ) : (
                            <p>No image available</p>
                        )}
                        <p><b>Customer Name:</b> {selectedReview.customerName || 'No data'}</p>
                        <p><b>Email:</b> {selectedReview.email_id || 'No data'}</p>
                        <p><b>Car Name:</b> {selectedReview.product_id.carname || 'No data'}</p>
                        <p><b>Rating:</b> {selectedReview.ratingOfCar || 'No data'}</p>
                        {/* Add other review details here */}
                    </div>
                )}
            </Modal>
        </section>
    );
};

export default OwnersReview;
