import React, { useState, useEffect, useRef } from 'react';
import { MdFileDownload } from "react-icons/md";
import { CSVLink } from 'react-csv';
import BigImage from './Onclickimage';
import { Table, Input, Button, DatePicker, Pagination, Spin, Modal } from 'antd';
import { FaChevronDown, FaFilter } from "react-icons/fa";
import moment from 'moment';

const { RangePicker } = DatePicker;
const { confirm } = Modal;

const Usertable = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [rusers, setRusers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(50);
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [filtered, setFiltered] = useState(false);
  const csvLinkRef = useRef();
  const [csvData, setCsvData] = useState([]);
  const [isAdvancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRegusers();
  }, []);

  useEffect(() => {
    if (filtered) {
      handleDateFilter();
    }
  }, [dateRange, filtered]);

  const getRegusers = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API}/enduser`;
    try {
      let result = await fetch(url);
      if (!result.ok) {
        throw new Error(`HTTP error! status: ${result.status}`);
      }
      let data = await result.json();
      setAllUsers([...data]);
      setRusers([...data]);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateFilter = () => {
    const [startDate, endDate] = dateRange;
    const filteredUsers = allUsers.filter(user => {
      const userDate = moment(user.createdAt);
      return userDate.isBetween(startDate, endDate, 'days', '[]');
    });
    setRusers(filteredUsers);

    const formattedCsvData = filteredUsers.map(user => ({
      Name: `${user.fname} ${user.lname}`,
      Mobile: user.mobile,
      Email: user.email,
      Pin: user.pin,
      DOB: user.DOB,
      Gender: user.gender,
      Profession: user.profession,
      Where: user.wheredidyouhearus
    }));
    setCsvData(formattedCsvData);
  };

  const csvDownloadEndUser = async () => {
    confirm({
      title: 'Do you want to export all users?',
      content: 'This will download a CSV file of all users.',
      onOk() {
        const result = fetch(`${process.env.REACT_APP_API}/api/exportUser`)
          .then(res => res.text())
          .then(csvData => {
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'users.csv';
            a.click();
            window.URL.revokeObjectURL(url);
          });
      },
      onCancel() {
        console.log('Export cancelled');
      },
    });
  };

  const searchHandle = async (event) => {
    let key = event.target.value;
    if (key) {
      let result = await fetch(`${process.env.REACT_APP_API}/search/${key}`);
      result = await result.json();
      if (result) {
        setRusers(result);
      }
    } else {
      getRegusers();
    }
  };

  const columns = [
    {
      title: 'Sr no',
      key: 'index',
      render: (_, __, index) => index + 1 + (currentPage - 1) * usersPerPage
    },
    {
      title: 'Name',
      dataIndex: 'fname',
      render: (text, user) => (
        <div className='d-flex'>
          <BigImage
            className='brand-image'
            src={`${process.env.REACT_APP_API}/userImages/${user.image}`}
            alt={`${user.fname} ${user.lname}`}
            crossorigin="anonymous"
          />
          <div className='image-left'>
            {user.fname} {user.lname}
            <br />
            <span className='textgray'>{user.email}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Pincode',
      dataIndex: 'pin',
    },
    {
      title: 'DOB',
      dataIndex: 'DOB',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
    },
    {
      title: 'Profession',
      dataIndex: 'profession',
    },
    {
      title: 'Heard Us From',
      dataIndex: 'wheredidyouhearus',
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      render: text => (
        <>
          {moment(text).format('YYYY-MM-DD')} <br />
          <span className='textgray'>{moment(text).format('HH:mm:ss')}</span>
        </>
      ),
    },
  ];

  const handleAdvanceSearchClick = () => {
    setAdvancedSearchVisible(!isAdvancedSearchVisible);
  };

  return (
    <section className='main-container-full'>
      <div className='d-flex justify-content-between mb-3 p-3'>
        <div className="h2">Users</div>
        <div className='d-flex justify-content-center align-items-center gap-3'>
          <Input.Search
            placeholder="Search by mobile, email, or pin code"
            onChange={searchHandle}
            style={{ width: 400 }}  // Adjusted width to 400px
          />
          <Button type="primary" onClick={handleAdvanceSearchClick}>
            Filter <FaChevronDown />
          </Button>
          <Button type="danger" onClick={csvDownloadEndUser}>
            <MdFileDownload /> Export
          </Button>
        </div>
      </div>

      {isAdvancedSearchVisible && (
        <div className='d-flex justify-content-center align-items-center gap-3 mb-3 flex-column pb-5'>
          <RangePicker
            value={dateRange}
            onChange={(dates) => setDateRange(dates)}
          />
          <div className='d-flex gap-3 w-25'>
            <Button type="primary" onClick={handleDateFilter}>
              <FaFilter /> Filter
            </Button>
            <CSVLink
              data={csvData}
              filename={`users-${new Date().toLocaleDateString()}.csv`}
              className="btn btn-lg active bg-danger-new text-white add-brand w-50"
            >
              <MdFileDownload /> Export Date-wise
            </CSVLink>
          </div>
        </div>
      )}

      {loading ? (
        <div className="d-flex align-items-center justify-content-center main-container-full">
          <Spin />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={rusers}
          pagination={false}
          rowKey="email"
        />
      )}

      <Pagination
        current={currentPage}
        total={rusers.length}
        pageSize={usersPerPage}
        onChange={setCurrentPage}
        className='pagination-numbers'
      />
    </section>
  );
};

export default Usertable;
